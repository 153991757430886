<template>
  <div class="radial-progress bg-base-content/25" :class="variantClass"
    :style="`--value:${refInterval}; --size:${variantSize}`" role="progressbar">{{ percentage }} %</div>
</template>

<script setup lang="ts">

interface Props {
  percentage: number;
  size: string;
}

const props = withDefaults(defineProps<Props>(), {
  percentage: 0,
  size: 'small',
});

const variantClass = computed(() => {
  const className = "bg-primary text-primary-content border-primary";
  if (props.percentage < 50) {
    return "text-error";
  }
  if (props.percentage > 50 && props.percentage < 75) {
    return "text-warning";
  }
  if (props.percentage > 75 && props.percentage < 90) {
    return "text-info";
  }
  if (props.percentage > 90) {
    return "text-success";
  }
  return className;
});
const variantSize = computed(() => {
  if (props.size === 'medium') {
    return '6rem';
  }
  if (props.size === 'large') {
    return '8rem';
  }
  return '4rem';
});

// create a setInterval to animate the progress bar
const refInterval = ref<number>(0);

onMounted(() => {
  setInterval(() => {
    if (refInterval.value < props.percentage) {
      refInterval.value += 1;
    }
  }, 12);
});
</script>
