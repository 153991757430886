<template>
  <div class="container mx-auto mb-2 mt-2 shadow-xl">
    <div v-if="candidate">
      <div class="label cursor-pointer justify-start flex">
        <p class="font-bold mr-4">Job Description Match</p>
        <span class="label-text mr-2">No</span>
        <input type="checkbox" class="toggle" v-model="candidate.jobdescription_match"
          :class="{ 'input-error': getError('jobdescription_match') }" />
        <span class="label-text ms-2">Yes</span>
        <div class="text-error">{{ getError("jobdescription_match") }}</div>
      </div>

      <div class="form-control w-full mt-2">
        <p class="font-bold">Name</p>
        <input type="text" class="input input-bordered w-full" v-model="candidate.name" placeholder="Enter Name"
          :class="{ 'input-error': getError('name') }" />
        <div class="text-error">{{ getError("name") }}</div>
      </div>

      <div class="form-control w-full mt-2">
        <p class="font-bold">Location</p>
        <input type="text" class="input input-bordered w-full mt-2" v-model="candidate.current_location"
          placeholder="Enter Location" :class="{ 'input-error': getError('current_location') }" />
        <div class="text-error">{{ getError("current_location") }}</div>
      </div>

      <div class="label cursor-pointer justify-start flex mt-2">
        <p class="font-bold mr-4">Location Match</p>
        <span class="label-text mr-2">No</span>
        <input type="checkbox" class="toggle" v-model="candidate.location_match"
          :class="{ 'input-error': getError('location_match') }" />
        <span class="label-text ms-2">Yes</span>
        <div class="text-error">{{ getError("location_match") }}</div>
      </div>

      <div class="form-control w-full mt-2">
        <p class="font-bold mb-2">Education</p>
        <div v-for="(item, index) in candidate.education" :key="item" class="btn btn-outline btn-sm mb-1 ms-1"
          @click="handleremoveEducationClick(index)">
          <button class="ms-1 font-bold">
            {{ item }} <span class="text-red-500 font-bold">x</span>
          </button>
        </div>
        <button class="btn btn-outline btn-sm mb-1 ms-1"
          @click="addNewEducationInputVisible = !addNewEducationInputVisible">
          <Icon name="uil:plus" />
        </button>
        <template v-if="addNewEducationInputVisible">
          <div class="form-control w-full mt-2">
            <input type="text" placeholder="Add a education qualification" class="input input-bordered w-full"
              v-model="newEducation" />
          </div>
          <button class="btn btn-sm mt-3" @click="handleCancelEducationClick">
            Cancel
          </button>
          <button class="btn btn-sm mt-3" @click="handleSaveEducationClick">
            Save
          </button>
        </template>
        <div class="text-error">{{ getError("education") }}</div>
      </div>

      <div class="label cursor-pointer justify-start flex mt-2">
        <p class="font-bold mr-4">Education Match</p>
        <span class="label-text mr-2">No</span>
        <input type="checkbox" class="toggle" v-model="candidate.education_match"
          :class="{ 'input-error': getError('education_match') }" />
        <span class="label-text ms-2">Yes</span>
        <div class="text-error">{{ getError("education_match") }}</div>
      </div>

      <div class="form-control w-full mt-2">
        <p class="font-bold">Email</p>
        <input type="text" class="input input-bordered w-full mt-2" v-model="candidate.email" placeholder="Enter Email"
          :class="{ 'input-error': getError('email') }" />
        <div class="text-error">{{ getError("email") }}</div>
      </div>

      <div class="label cursor-pointer justify-start flex mt-2">
        <p class="font-bold mr-4">Immediately Available</p>
        <span class="label-text mr-2">No</span>
        <input type="checkbox" class="toggle" v-model="candidate.immidiately_available"
          :class="{ 'input-error': getError('immidiately_available') }" />
        <span class="label-text ms-2">Yes</span>
        <div class="text-error">{{ getError("immidiately_available") }}</div>
      </div>

      <div class="form-control w-full mt-2">
        <p class="font-bold">Job Title</p>
        <input type="text" class="input input-bordered w-full mt-2" v-model="candidate.job_title"
          placeholder="Enter Job Title" :class="{ 'input-error': getError('job_title') }" />
        <div class="text-error">{{ getError("job_title") }}</div>
      </div>

      <div class="form-control w-full mt-2">
        <p class="font-bold">Last Job Role</p>
        <input type="text" class="input input-bordered w-full mt-2" v-model="candidate.last_job_role"
          placeholder="Enter Last Job Role" />
      </div>

      <div class="form-control w-full mt-2">
        <p class="font-bold mb-2">Edit Previous Companies</p>
        <div v-for="(company, index) in candidate.previous_companies" :key="index" class="mb-4 p-4 border rounded-lg">
          <p class="font-bold text-lg mb-2">Company {{ index + 1 }}</p>
          <label class="block mb-2">
            <span class="font-semibold">Company Name:</span>
            <input type="text" v-model="company.company_name" class="input input-bordered w-full mt-1" :class="{
              'input-error': getError(
                `previous_companies,${index},company_name`
              ),
            }" />
            <div class="text-error">
              {{ getError(`previous_companies,${index},company_name`) }}
            </div>
          </label>
          <label class="block mb-2">
            <span class="font-semibold">Start Date:</span>
            <input type="text" v-model="company.start_date" class="input input-bordered w-full mt-1" :class="{
              'input-error': getError(
                `previous_companies,${index},start_date`
              ),
            }" />
            <div class="text-error">
              {{ getError(`previous_companies,${index},start_date`) }}
            </div>
          </label>
          <label class="block mb-2">
            <span class="font-semibold">End Date:</span>
            <input type="text" v-model="company.end_date" class="input input-bordered w-full mt-1" :class="{
              'input-error': getError(
                `previous_companies,${index},end_date`
              ),
            }" />
            <div class="text-error">
              {{ getError(`previous_companies,${index},end_date`) }}
            </div>
          </label>
          <label class="block mb-2">
            <span class="font-semibold">Tenure in Company:</span>
            <input type="text" v-model="company.tenure_in_company" class="input input-bordered w-full mt-1" :class="{
              'input-error': getError(
                `previous_companies,${index},tenure_in_company`
              ),
            }" />
            <div class="text-error">
              {{ getError(`previous_companies,${index},tenure_in_company`) }}
            </div>
          </label>
        </div>
      </div>

      <div class="form-control w-full mt-2">
        <p class="font-bold">Average Tenure Duration</p>
        <input type="text" class="input input-bordered w-full mt-2" v-model="candidate.average_tenure_duration"
          placeholder="Enter Average Tenure Duration" :class="{
            'input-error': getError(`average_tenure_duration`),
          }" />
        <div class="text-error">
          {{ getError(`average_tenure_duration`) }}
        </div>
      </div>

      <div class="form-control w-full mt-2">
        <p class="font-bold">Total Year of Experience</p>
        <input type="text" class="input input-bordered w-full mt-2" v-model="candidate.total_year_of_experience"
          placeholder="Enter Total year of experinece" :class="{
            'input-error': getError(`total_year_of_experience`),
          }" />
        <div class="text-error">
          {{ getError(`total_year_of_experience`) }}
        </div>
      </div>

      <div class="form-control w-full mt-2">
        <p class="font-bold">Number</p>
        <input type="text" class="input input-bordered w-full mt-2" v-model="candidate.number"
          placeholder="Enter Number" :class="{
            'input-error': getError(`number`),
          }" />
        <div class="text-error">
          {{ getError(`number`) }}
        </div>
      </div>

      <div class="form-control w-full mt-2 block">
        <p class="font-bold mb-2">Skills</p>
        <div v-for="(item, index) in candidate.skills" :key="item" class="btn btn-outline btn-sm mb-1 ms-1"
          @click="handleremoveSkillClick(index)">
          <button class="ms-1 font-bold">
            {{ item }} <span class="text-red-500 font-bold">x</span>
          </button>
        </div>
        <button class="btn btn-outline btn-sm mb-1 ms-1" @click="addNewSkillInputVisible = !addNewSkillInputVisible">
          <Icon name="uil:plus" />
        </button>
        <template v-if="addNewSkillInputVisible">
          <div class="form-control w-full mt-2">
            <input type="text" placeholder="Add a skill" class="input input-bordered w-full" v-model="newSkill" />
          </div>
          <button class="btn btn-sm mt-3" @click="handleCancelSkillClick">
            Cancel
          </button>
          <button class="btn btn-sm mt-3 ml-1" @click="handleSaveSkillClick">
            Save
          </button>
        </template>
        <div class="text-error">
          {{ getError(`skills`) }}
        </div>
      </div>

      <div class="form-control w-full mt-2">
        <p class="font-bold mb-2">Good to have skills</p>
        <ul>
          <li v-for="(skill, index) in candidate.good_to_have_skills" :key="index">
            <label class="flex items-center">
              <input type="checkbox" class="form-checkbox" v-model="skill.match" />
              <span class="ml-2">{{ skill.skill_name }}</span>
              <span v-if="skill.reasoning" class="ml-2">({{ skill.reasoning }})</span>
            </label>
          </li>
        </ul>
      </div>

      <div class="form-control w-full mt-2">
        <p class="font-bold">Required Skills</p>

        <div class="mt-4">
          <div class="p-4 border rounded-lg">
            <p class="font-bold">High Priority Skills</p>
            <ul>
              <li v-for="(skill, index) in candidate.required_skills
                .high_priority" :key="index">
                <label class="flex items-center">
                  <input type="checkbox" class="form-checkbox" v-model="skill.match" />
                  <span class="ml-2">{{ skill.skill_name }}</span>
                  <!-- <span v-if="skill.reasoning" class="ml-2">({{ skill.reasoning }})</span> -->
                </label>
              </li>
            </ul>
          </div>
        </div>

        <div class="mt-4">
          <div class="p-4 border rounded-lg">
            <p class="font-bold">Low Priority Skills</p>
            <ul>
              <li v-for="(skill, index) in candidate.required_skills
                .low_priority" :key="index">
                <label class="flex items-center">
                  <input type="checkbox" class="form-checkbox" v-model="skill.match" />
                  <span class="ml-2">{{ skill.skill_name }}</span>
                  <!-- <span v-if="skill.reasoning" class="ml-2">({{ skill.reasoning }})</span> -->
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="mt-4 float-right">
        <button type="button"
          class="inline-flex justify-center rounded-md border border-transparent bg-red-400 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
          @click="save">
          Save Changes
        </button>
        <button type="button"
          class="inline-flex ml-3 justify-center rounded-md border border-transparent bg-green-400 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
          @click="handleCancel">
          No, Cancel
        </button>
      </div>
    </div>
  </div>

</template>

<script lang="ts" setup>
const prop = defineProps<Props>();

import { ref } from "vue";
import { useMyCandidatesStore } from "~/store/candidate";
import { useMyAppStore } from "~/store/app";
import type { Candidate } from "~/types/candidate";
import { z } from "zod";
import useValidation from "~/composables/useValidation";
const candidateStore = useMyCandidatesStore();
const appStore = useMyAppStore();
const candidate = ref<Candidate | null>(prop.candidate ?? null);
const addNewEducationInputVisible = ref<boolean>(false);
const addNewSkillInputVisible = ref<boolean>(false);
const newEducation = ref<string>("");
const newSkill = ref<string>("");
const formSubmitting = ref<boolean>(false);

type Props = {
  candidate?: Candidate;
  candidateId: string;
};



const handleremoveEducationClick = (educationIndex: number) => {
  candidate!.value?.education.splice(educationIndex, 1);
};

const handleSaveEducationClick = (event: Event) => {
  candidate!.value?.education.push(newEducation.value);
  newEducation.value = "";
  addNewEducationInputVisible.value = false;
  2;
};

const handleCancelEducationClick = () => {
  newEducation.value = "";
  addNewEducationInputVisible.value = false;
};

const handleremoveSkillClick = (skillIndex: number) => {
  candidate!.value?.skills.splice(skillIndex, 1);
};

const handleCancelSkillClick = () => {
  newSkill.value = "";
  addNewSkillInputVisible.value = false;
};

const handleSaveSkillClick = (event: Event) => {
  candidate!.value?.skills.push(newSkill.value);
  newSkill.value = "";
  addNewSkillInputVisible.value = false;
};

// validation
const previousCompaniesSchema = z.object({
  company_name: z.string().min(1, "Name is required"),
  start_date: z.string().min(1, "Start date is required"),
  end_date: z.string().min(1, "End date is required"),
  tenure_in_company: z.string().min(1, "Tenure is required"),
});

const validationSchema = z.object({
  jobdescription_match: z.boolean(),
  name: z.string().min(1, "Name is required"),
  current_location: z.string().min(1, "Current location is required"),
  location_match: z.boolean(),
  education: z.array(z.string()).min(1, "Education is required"),
  education_match: z.boolean(),
  email: z.string().email({ message: "Invalid email address" }),
  immidiately_available: z.boolean(),
  job_title: z.string().min(1, "Job title is required"),
  last_job_role: z.string().optional(),
  previous_companies: z.array(previousCompaniesSchema).min(0),
  average_tenure_duration: z.string().refine(
    (value: string) => {
      return (
        !candidate!.value?.previous_companies ||
        candidate!.value?.previous_companies.length === 0 ||
        value.length >= 1
      );
    },
    {
      message: "Average Tenure Duration is required",
    }
  ),
  total_year_of_experience: z.string().refine(
    (value: string) => {
      return (
        !candidate.value?.previous_companies ||
        candidate.value?.previous_companies.length === 0 ||
        value.length >= 1
      );
    },
    {
      message: "Total year if experience is required",
    }
  ),
  number: z.string().min(10, "Enter valid phone number"),
  skills: z.array(z.string()).min(1, "Minimum of one skill is required"),
});

const { validate, errors, isValid, getError, scrolltoError } = useValidation(
  validationSchema,
  candidate.value || {},
  {
    mode: "lazy",
  }
);


const emit = defineEmits(["on-close-modal"]);
const save = async () => {
  if (!candidate.value) return;
  await validate();
  if (isValid.value) {
    formSubmitting.value = true;
    const res = await candidateStore.updateCandidate(candidate.value, candidate.value.hash_id);
    if (res.status !== 200) {
      appStore.error({
        text: res.error,
      })
    } else {
      formSubmitting.value = false;
      console.log(res);
      emit("on-close-modal");
    }
  } else {
    formSubmitting.value = false;
    scrolltoError(".text-error", { offset: 24 });
  }
};

const handleCancel = () => {
  emit("on-close-modal");
};
const isLoading = ref<boolean>(false);

onMounted(async () => {
  if (prop.candidate) return;
  if (!prop.candidateId) return;
  try {
    isLoading.value = true;
    const res = await candidateStore.findOneCandidate(prop.candidateId);
    if (res.status !== 200) {
      appStore.error({
        text: res.error,
      })
    } else {
      candidate.value = res.data;
    }
  }
  catch (error) {
    console.log(error);
  }
  finally {
    isLoading.value = false;
  }
});
</script>
